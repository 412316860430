<template>
	<div id="app">
		<EighthWall
			v-if="!isDesktop && (stateCameraAccess || stateAR)"
			v-on:cameraStateChanged="onCameraStateChanged"
			:state="state"
			:isIOS="isIOS"
			:lang="lang"
		/>
		<CameraAccess v-if="!isDesktop && stateCameraAccess"
			:camAccess="camAccess"
		/>
		<Desktop 
			v-if="isDesktop"
		/>
		<!-- <Landing
			v-if="!isDesktop && stateHome"
			v-on:onContClicked="onContClicked"
			:lang="lang"
		/> -->
	</div>
</template>

<script>
import EighthWall from "./components/EighthWall.vue";
import Landing from "./components/Landing.vue";
import CameraAccess from "./components/CameraAccess.vue";
import Desktop from "./components/Desktop.vue";

import Bowser from "bowser";

import en from '@/locales/en.json';
import fr from '@/locales/fr.json';

const STATE_HOME = "home";
const STATE_CAMERA_ACCESS = "camera";
const STATE_AR = "ar";

export default {
	name: "App",
	components: {
    EighthWall,
    // Landing,
    CameraAccess,
    Desktop
},
	data: function () {
			return {
				state: STATE_CAMERA_ACCESS,
				lang: "",
				camAccess: true,
			};
		},
	computed: {
		stateHome: function () {
			return this.state === STATE_HOME;
		},
		stateCameraAccess: function () {
			return this.state === STATE_CAMERA_ACCESS;
		},
		stateAR: function () {
			return this.state === STATE_AR;
		},
		isDesktop: function () {
			return (
				Bowser.parse(window.navigator.userAgent).platform.type === "desktop"
			);
		},
		isIOS: function () {
			return Bowser.parse(window.navigator.userAgent).os.name === "iOS";
		}
	},
	created: function () {
		document.title = "Karat Ar";
		this.setLocale("en");
	},
	mounted: function () {
		// this.setLocale("en");
	},
	methods: {
		setLocale: function(loc) {
			this.lang = loc;
			if(loc == "en")
				this.$store.commit('setLocale', en);
			else
				this.$store.commit('setLocale', fr);
		},
		onContClicked: function () {
			this.state = STATE_CAMERA_ACCESS;
		},
		onCameraStateChanged: function (state) {
			if (state === "hasVideo") {
				this.camAccess = true;
				this.state = STATE_AR;
			}
			else if ( state === "failed" ) {
				console.log("CAMERA PERMISSION FAILED");
				this.camAccess = false;
			}
		},
	},
};
</script>

<style lang="scss">

@font-face {
	font-family: "SF Pro Display";
	src: url("/assets/fonts/SF-Pro-Display-Regular.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "SF Pro Display";
	src: url("/assets/fonts/SF-Pro-Display-Bold.otf") format("opentype");
	font-weight: 700;
	font-style: normal;
}

html,
body {
	position: fixed;
	width: 100%;
	height: -webkit-fill-available;
	background: #0D2F76;
}

body {
	position: fixed;
	width: 100%;
	min-height: 100%;
	min-height: -webkit-fill-available;
	background: #ffffff;
}

#app {
	font-family: "Bruxism Bold", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	// text-align: center;
	color: #ffffff;

	overflow: hidden;
	overscroll-behavior: none;

	z-index: 5;
}
</style>
